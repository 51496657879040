import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout     from "../components/layout"
import Hero       from "../blocks/index/hero"
import Features   from "../blocks/index/features"
import Players    from "../blocks/index/players"
import Portfolio  from "../blocks/index/portfolio"
import Services   from "../blocks/index/services"
import News       from "../blocks/index/news"
import Partners   from "../blocks/index/partners"

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Layout showSocial={ true } smoothScroll={ true } lang="ru">
        <Helmet>
          <title>Catenaccio Sports Agency</title>
        </Helmet>
        <Hero image={data.heroImage} />
        <Features />
        <News news={ data.news.nodes } />
        <Players players={data.players.nodes} />
        <Portfolio transfers={data.transfers.nodes} law={ data.law.nodes } />
        <Services />
        <Partners lang="ru" />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1680) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    players: allPlayersYaml {
      nodes {
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        first_name
        first_name_ru
        last_name
        last_name_ru
        club
        club_ru
      }
    }
    transfers: allTransfersYaml {
      nodes {
        player
        player_ru
        from
        from_ru
        to
        to_ru
        image {
          childImageSharp {
            fixed(width: 130) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        from_logo {
          childImageSharp {
            fixed(width: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        to_logo {
          childImageSharp {
            fixed(width: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    law: allLawYaml {
      nodes {
        player
        player_ru
        date
        type
        pdf
      }
    }
    news: allNewsYaml {
      nodes {
        slug
        title
        subtitle
        date
        image {
          childImageSharp {
            fluid(maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
