import React from "react"

export default class PortfolioBlock extends React.Component {

  renderTransfer(transfer) {
    return (
      <div key={ `${transfer.player} ${transfer.from}` }  className="portfolio-item col-lg-4 col-md-6 col-12 wow fadeIn">
        <div className="images">
          <img className="team-logo" src={ transfer.from_logo.childImageSharp.fixed.src } alt={ transfer.from_ru } />
          <img className="player-photo" src={ transfer.image.childImageSharp.fixed.src } alt={ transfer.player_ru } />
          <img className="team-logo" src={ transfer.to_logo.childImageSharp.fixed.src } alt={ transfer.to_ru } />
        </div>
        <h4 className="player-name">{ transfer.player_ru }</h4>
        <p className="transfer-meta"><strong>{ transfer.from_ru }</strong> → <strong>{ transfer.to_ru }</strong></p>
      </div>
    )
  }

  renderDoc = (doc) => {
    return (
      <a key={ doc.pdf } href={ doc.pdf } target="_blank" rel="noopener noreferrer" className="portfolio-item col-lg-4 col-md-6 col-12 wow fadeIn">
        <div className="images">
        </div>
        <h4 className="player-name">{ doc.player_ru }</h4>
        <p className="transfer-meta"><strong>{ doc.date }</strong></p>
      </a>
    )
  }

  render() {
   return (
    <section className="portfolio">
      <h2 className="section-title anchor" id="transfers">Трансферы</h2>
      <h3 className="section-subtitle">Судите не по словам, а по делам</h3>
      <div className="container">
        <div className="row">
          { this.props.transfers.map(transfer => this.renderTransfer(transfer)) }
        </div>
      </div>
      <h2 className="section-title anchor" id="law">Спортивное право</h2>
       <h3 className="section-subtitle">Контракты и трансферы со стороны клубов и игроков</h3>

       <div className="container">
         <p>Catenaccio консультирует по вопросам контрактов и представляет интересы как игроков, так и клубов. Мы консультируем по следующим вопросам: </p>
         <ul>
           <li>Все аспекты контрактов игроков и сопутствующей документации; </li>
           <li>Стандартные игровые контракты и соглашения с сотрудниками команд для лиг, команд и руководящих органов; </li>
           <li>Трансферы как внутри страны, так и на международном уровне, включая составление документации по трансферу или аренде; </li>
           <li>Переговоры и составление контрактов с игроками, включая структуру бонусов и положений об обязательной сумме выкупа; </li>
           <li>Предконтрактные и опционные договоренности; </li>
           <li>Права на изображение </li>
           <li>Международные и внутренние регуляции и соответствие общим нормативным требованиям; </li>
           <li>Дела по статье 17 в соответствии с Регламентом ФИФА; </li>
           <li>Вопросы, связанные с правом собственности третьих лиц, и соблюдение требований; </li>
           <li>Случаи компенсации за обучение и солидарные выплаты; </li>
           <li>Проблемы в рамках Регламента о посредничестве, включая содержание договоров о представительстве, допустимую структуру вознаграждения и общие вопросы комплаенса; </li>
           <li>Компенсация игрокам без контракта в Англии, включая представительство в Комитете по вознаграждениям в профессиональном футболе;</li>
           <li>Правила перемещения несовершеннолетних в соответствии с Регламентом ФИФА; и </li>
           <li>Назначение тренеров и сотрудников тренерского штаба, включая переговоры и составление договоров об оказании услуг, компромиссных соглашений и разрешения споров в Высшем спортивном суде в Лозанне (CAS). </li>
         </ul>

       </div>

       <h3 className="section-subtitle">Разрешение споров</h3>
       <div className="container">
         <p>Catenaccio консультирует клиентов по вопросам споров и их разрешения, в том числе в следующих инстанциях: </p>
         <ul>
           <li>Механизмы разрешения споров в руководящих органах спорта, включая FA, Английскую Премьер-лигу, национальные футбольные лиги мира, УЕФА, ФИФА. </li>
           <li>Спортивный арбитражный суд; </li>
           <li>Инстанции по арбитражу и посредничеству в вопросах спорта; и </li>
           <li>Гражданские и уголовные суды. </li>
         </ul>
         <p>Catenaccio консультирует по всем дисциплинарным вопросам, в том числе: </p>
         <ul>
           <li>Дисциплинарные процедуры и процессы; b</li>
           <li>Представительство в дисциплинарных комиссиях, слушаниях, в том числе апелляционных. </li>
         </ul>
       </div>

       <h3 className="section-subtitle">Личное представительство</h3>
       <div className="container">
         <p>Catenaccio обладает большим опытом консультирования лиц, работающих в профессиональном спорте, игроков и их агентов/посредников в отношении: </p>
         <ul>
           <li>Игровых контрактов; </li>
           <li>Соглашений о предоставлении услуг; </li>
           <li>Прав на изображение </li>
           <li>Клеветы; </li>
           <li>Медиа- и антикризисного управления; и </li>
           <li>Уголовных дел и гражданских споров. </li>
         </ul>
       </div>


      <div className="container">
        <div className="row">
          { this.props.law.map(doc => this.renderDoc(doc)) }
        </div>
      </div>
    </section>
  )
  }
}
