import React from "react"

const FeaturesBlock = () => (
  <section className="features">
    <div className="container">
      <div className="row">
        <div className="feature col-md-4 col-12 wow fadeInDown">
          <div className="feature-icon help"></div>
          <p>Берём игроков под свою опеку и помогаем им во всех правовых вопросах. В любой ситуации мы на стороне игрока – всегда продавливаем его позицию и добиваемся желаемого им исхода. Мы представляем интересы футболистов в FIFA и CAS.</p>
        </div>
        <div className="feature col-md-4 col-12 wow fadeInDown" style={ { animationDelay: '0.3s' } }>
          <div className="feature-icon search"></div>
          <p>Если игрок решил сменить клуб, мы делаем всё, чтобы это случилось. Рекламируем игрока и находим подходящие варианты на международном рынке, составляем контракт на выгодных всем условиях и юридически сопровождаем его подписание.</p>
        </div>
        <div className="feature col-md-4 col-12 wow fadeInDown" style={ { animationDelay: '0.6s' } }>
          <div className="feature-icon docs"></div>
          <p>Мы очень сильны в спортивной юриспруденции. Полностью контролируем документооборот всех наших игроков, а при возникновении спорных ситуаций в 90% случаев выигрываем дела.</p>
        </div>
      </div>
    </div>
  </section>
)

export default FeaturesBlock
