import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default class PlayersBlock extends React.Component {

  renderPlayer(player) {
    return (
      <div key={ player.slug } className="player col-xl-3 col-lg-4 col-sm-6 col-12 wow fadeInUp">
        <Link to={ `/players/${player.slug}` } className="player-content">
          <Img fluid={ player.image.childImageSharp.fluid }
              alt={ `${player.first_name_ru} ${player.last_name_ru}` }
              width='100%'
            />
          <div className="info">
            <h3 className="name">{ player.first_name_ru }<br/>{ player.last_name_ru }</h3>
            <p className="club">{ player.club_ru }</p>
          </div>
        </Link>
      </div>
    )
  }

  render() {
   return (
    <section className="players">
      <h2 className="section-title anchor" id="players">Игроки</h2>
      <h3 className="section-subtitle">Люди, которые доверяют нам во всём за пределами поля</h3>
      <div className="container">
        <div className="row">
          { this.props.players.map(player => this.renderPlayer(player)) }
        </div>
      </div>
    </section>
   )
  }
}
