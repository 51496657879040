import React from "react"

const ServicesBlock = () => (
  <>
  <section className="services">
    <h2 className="section-title anchor" id="services">Услуги</h2>
    <h3 className="section-subtitle">Идентификация проблем клуба</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
            <thead>
              <tr>
                <th></th>
                <th>Базовый пакет</th>
                <th>Pro</th>
                <th>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Анализ состава</td>
                <td>Быстрый анализ состава</td>
                <td colSpan="2">Комплексный анализ состава и востребованности игроков на рынке, анализ травматичности игроков</td>
              </tr>
              <tr>
                <td>Проблемы в составе</td>
                <td>
                  Идентификация двух проблемных позиций;<br />
                  Cравнение с одним игроком из портфолио агента
                </td>
                <td>
                  Идентификация двух проблемных позиций;<br />
                  Сравнение со всеми релевантными игроками из портфолио агента
                </td>
                <td>
                  Идентификация качеств игрока, которые требуются/усилят игру команды на проблемных позициях;<br />
                  Подбор игроков, закрывающих проблемные позиции
                </td>
              </tr>
              <tr className="bool-param">
                <td>Анализ тактики и статистических данных за последний сезон</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Анализ типологии созданных и допущенных моментов</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Распределение игрового времени команды по каждой позиций</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Видеоанализ игры команды (билдап, игра в середине поля, завершение)</td>
                <td></td>
                <td></td>
                <td><span className="yes"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h3 className="section-subtitle">Презентация игрока</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
              <thead>
                <tr>
                  <th></th>
                  <th>Базовый пакет</th>
                  <th>Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bool-param">
                  <td>Базовая статистика игрока, его история и карьера</td>
                  <td><span className="yes"></span></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Подборка отчётов по игре за прошлый сезон</td>
                  <td><span className="yes"></span></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Подписка на отчеты об игре на сезон по выбранным параметрам</td>
                  <td></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Выявление сильных сторон игрока и видеонарезка по ним</td>
                  <td></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr>
                  <td>Уровень игрока относительно лиги</td>
                  <td>Простое сравнение</td>
                  <td>Сравнение по сильным сторонам игрока</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
    <h3 className="section-subtitle">Подбор клуба для игрока</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
            <thead>
              <tr>
                <th></th>
                <th>Анализ по любой лиге</th>
                <th>Анализ по макрорегиону<br />(Западная/Восточная Европа)</th>
                <th>Анализ по азиатскому рынку</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bool-param">
                <td>Подробное описание игрока, статистика, история, карьера и описание сильных/слабых сторон</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Анализ стиля игры команд (тактика, темп, философия)</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Подбор 3-х команд наиболее подходящих для игрока + детальный анализ и выявление преимуществ по сравнению с конкурентами из этих команд</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Подборка 20 клубов, подходящих для игрока (стоимость, зарплата, трансферная история)</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Сравнение игрока со всеми игроками лиги на его позиции</td>
                <td><span className="yes"></span></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Оценка вероятности трансфера</td>
                <td>В каждом клубе лиги</td>
                <td colSpan="2">В каждом из 20 подобранных клубов</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <section className="services services-mobile">
    <h2 className="section-title anchor" id="services-mobile">Услуги</h2>
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <h3 className="services-mobile-subtitle">Идентификация проблем клуба</h3>
          <h4 className="services-mobile-tariff">Базовый пакет</h4>
          <ul className="services-mobile-list">
            <li>Быстрый анализ состава</li>
            <li>Идентификация двух проблемных позиций</li>
            <li>Cравнение с одним игроком из портфолио агента</li>
            <li>Анализ тактики и статистических данных за последний сезон</li>
          </ul>
          <h4 className="services-mobile-tariff">Pro</h4>
          <ul className="services-mobile-list">
            <li>Комплексный анализ состава и востребованности игроков на рынке, анализ травматичности игроков</li>
            <li>Идентификация двух проблемных позиций</li>
            <li>Сравнение со всеми релевантными игроками из портфолио агента</li>
            <li>Анализ тактики и статистических данных за последний сезон</li>
            <li>Анализ типологии созданных и допущенных моментов</li>
            <li>Распределение игрового времени команды по каждой позиций</li>
          </ul>
          <h4 className="services-mobile-tariff">Premium</h4>
          <ul className="services-mobile-list">
            <li>Все услуги из пакета <strong>Pro</strong></li>
            <li>Видеоанализ игры команды (билдап, игра в середине поля, завершение)</li>
          </ul>
          <h3 className="services-mobile-subtitle">Презентация игрока</h3>
          <h4 className="services-mobile-tariff">Базовый пакет</h4>
          <ul className="services-mobile-list">
            <li>Базовая статистика игрока, его история и карьера</li>
            <li>Подборка отчётов по игре за прошлый сезон</li>
            <li>Простое сравнение уровня игрока относительно лиги</li>
          </ul>
          <h4 className="services-mobile-tariff">Premium</h4>
          <ul className="services-mobile-list">
            <li>Базовая статистика игрока, его история и карьера</li>
            <li>Подборка отчётов по игре за прошлый сезон</li>
            <li>Подписка на отчеты об игре на сезон по выбранным параметрам</li>
            <li>Выявление сильных сторон игрока и видеонарезка по ним</li>
            <li>Cравнение уровня игрока относительно лиги по его сильным сторонам</li>
          </ul>
          <h3 className="services-mobile-subtitle">Подбор клуба для игрока</h3>
          <ul className="services-mobile-list">
            <li>Подробное описание игрока, статистика, история, карьера и описание сильных/слабых сторон</li>
            <li>Анализ стиля игры команд (тактика, темп, философия)</li>
            <li>Подбор 3-х команд наиболее подходящих для игрока + детальный анализ и выявление преимуществ по сравнению с конкурентами из этих команд</li>
          </ul>
          <h4 className="services-mobile-tariff">Анализ по любой лиге</h4>
          <ul className="services-mobile-list">
            <li>Сравнение игрока со всеми игроками лиги на его позиции	</li>
            <li>Оценка вероятности трансфера в каждом клубе лиги</li>
          </ul>
          <h4 className="services-mobile-tariff">Анализ по макрорегиону (Западная/Восточная Европа) или по азиатскому рынку</h4>
          <ul className="services-mobile-list">
            <li>Подборка 20 клубов, подходящих для игрока (стоимость, зарплата, трансферная история)</li>
            <li>Оценка вероятности трансфера в каждом из 20 выбранных клубов</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  </>
)

export default ServicesBlock
