import React from "react"
import Riga from "../../images/partners/riga.inline.svg";

const PartnersBlock = ({ lang }) => (
  <section className="partners">
    <h2 className="section-title">{ lang === 'ru' ? 'Партнёры' : 'Partners' }</h2>
    <div className="container">
      <div className="row">
        <div className="col-12 partners-list">
          <a href="https://rigafc.lv/" target="_blank" rel="noopener noreferrer" className="partner">
            <Riga alt="FC Riga"/>
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default PartnersBlock
