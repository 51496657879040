import React from "react"

import Logo from "../../images/logo-white.inline.svg";

const HeroBlock = () => (
  <section className="welcome-screen">
    <div className="half company">
      <Logo className="logo" />
    </div>
    <div className="half about">
      <div className="text">
        <p>Агентство, для которого интересы спортсмена превыше всего.</p>
        <p>Индивидуально работаем с каждым нашим игроком. Вместо клиентов у нас друзья, которые доверяют нам все свои дела. Мы&nbsp;гордимся нашими юридическими навыками — на нас вся работа с&nbsp;документами игрока, чтобы он мог сосредоточиться на футболе.</p>
        <p>У нас большой опыт на международном трансферном рынке. Мы&nbsp;находим лучшие варианты для наших игроков и полностью сопровождаем их до подписания контракта с новым клубом.</p>
      </div>
    </div>
    <div className="scroll-down"></div>
  </section>
)

export default HeroBlock
